<template>
    <div class="page-table mainDiv mb-100" id="affix-container">
        <div class="card-base baslikBg animated fadeInDown">
            {{$t("src.views.apps.slider.guncelleme.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>

        <el-row :gutter="10" v-loading="loading || detailLoading"
            :element-loading-text='loading ? $t("src.views.apps.slider.guncelleme.updateLoading") : $t("src.views.apps.slider.guncelleme.detailLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="sliderForm" :rules="rulesSlider" ref="sliderForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.slider.tanimlama.anaBaslik")' prop="anaBaslik">:
                                    <el-input size="small" v-model="sliderForm.anaBaslik" :placeholder='$t("src.views.apps.slider.tanimlama.anaBaslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.slider.tanimlama.altBaslik")' prop="altBaslik">:
                                    <el-input size="small" v-model="sliderForm.altBaslik" :placeholder='$t("src.views.apps.slider.tanimlama.altBaslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.slider.tanimlama.aciklama")' prop="aciklama">:
                                    <el-input size="small" type="textarea" v-model="sliderForm.aciklama" :placeholder='$t("src.views.apps.slider.tanimlama.aciklamaPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                               
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item>
                                    <el-checkbox v-model="checked">{{$t("src.views.apps.slider.guncelleme.sliderLinkOlsunMu")}}</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <el-collapse v-if="checked" style="margin-top: 10px" v-model="activeCollapse">
                        <el-row :gutter="10">
                            <el-col :lg="12" :md="12" :sm="24" :xs="24">
                                <el-collapse-item :title='$t("src.views.apps.slider.tanimlama.link1Baslik")' name="1" class="animated fadeInLeft">
                                    <el-row>
                                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                            <el-form-item :label='$t("src.views.apps.slider.tanimlama.link")' prop="link1">:
                                                <el-input size="small" v-model="sliderForm.link1" :placeholder='$t("src.views.apps.slider.tanimlama.linkPlace")'></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                            <el-form-item :label='$t("src.views.apps.slider.tanimlama.linkName")' prop="link1">:
                                                <el-input size="small" v-model="sliderForm.linkButon1" :placeholder='$t("src.views.apps.slider.tanimlama.butonPlace")'></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-collapse-item>
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="24" :xs="24">
                                <el-collapse-item :title='$t("src.views.apps.slider.tanimlama.link2Baslik")' name="2" class="animated fadeInRight">
                                    <el-row>
                                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                            <el-form-item :label='$t("src.views.apps.slider.tanimlama.link")' prop="link2">:
                                                <el-input size="small" v-model="sliderForm.link2" :placeholder='$t("src.views.apps.slider.tanimlama.linkPlace")'></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                            <el-form-item :label='$t("src.views.apps.slider.tanimlama.linkName")' prop="link1">:
                                                <el-input size="small" v-model="sliderForm.linkButon2" :placeholder='$t("src.views.apps.slider.tanimlama.butonPlace")'></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-collapse-item>
                            </el-col>
                        </el-row>
                    </el-collapse>
                    <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                    <draggable data-source="juju" class="list-group" group="a">
                        <el-collapse-item class="card-shadow--medium" title="Resim Ayarları" name="3">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.resim") + " (800x600)"' prop="resim">:
                                        <el-upload
                                        id="sliderguncelle-resim"
                                        class="resimler"
                                        action=""
                                        list-type="picture-card"
                                        :file-list="imageListMain"
                                        :auto-upload="false"
                                        accept=".jpg,.jpeg,.png"
                                        :on-change="imageUpload"
                                        :on-remove="imageRemove">
                                            <i slot="default" class="el-icon-plus"></i>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </draggable>
                </el-collapse>
            </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item name="7" :title='$t("src.views.apps.slider.tanimlama.durum")'>
                            <el-row>
                                <el-button  size="mini" v-on:click="sliderGuncelle('sliderForm')" class="onayBtn" type="primary">
                                    {{$t("src.views.apps.slider.guncelleme.guncelle")}}
                                </el-button>
                            </el-row>
                        </el-collapse-item>

                        <el-collapse-item name="8" :title='"Slider Position"'>
                            <el-row>
                                <el-col class="col-p">
                                    <el-form-item :label='"Slider Position"' prop="sliderKonum">:
                                        <el-select size="small" v-model="sliderForm.sliderKonum" style="width: 100%">
                                            <el-option value="0" :label='"Top Slider"'></el-option>
                                            <el-option value="1" :label='"Middle Slider"'></el-option>
                                            <el-option value="2" :label='"Bottom Slider"'></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>

<script>
    import sliderService from '../../../WSProvider/SliderService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import draggable from 'vuedraggable';
    import JQuery from 'jquery';

    let $ = JQuery;

    export default {
        name: "SliderDuzenle",
        components: {
            Resim,
            draggable,
        },
        data() {
            return {
                detailLoading: false,
                loading: false,
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                // Form
                checked: false,
                imageListMain: [],
                updateSliderData: "",
                updateSliderDataID: "",
                sliderForm: {
                    anaBaslik: "",
                    altBaslik: "",
                    aciklama: "",
                    resim: [],
                    durum: "1",
                    link1: '',
                    linkButon1: '',
                    link2: '',
                    linkButon2: '',
                    sliderKonum: '',
                },
                rulesSlider: {
                    anaBaslik: [{
                        required: true,
                        message: "Please Enter Title",
                        trigger: 'blur'
                    }],
                    altBaslik: [{
                        required: true,
                        message: "Please Enter Sub Title",
                        trigger: 'blur'
                    }],
                    aciklama: [{
                        required: true,
                        message: "Please Enter Content",
                        trigger: ['blur', 'trigger']
                    }],
                    resim: [{
                        required: true,
                        message: "Please Select Image",
                        trigger: ['blur', 'trigger']
                    }],
                },
            }
        },
        computed: {
            sliderStoreData(){
                return this.$store.getters.getSliderData
            }
        },
        watch: {
            sliderStoreData(val){
                this.updateSliderDataID = this.$store.getters.getSliderData
                this.getSliderDetay();
            }
        },
        mounted() {
            this.updateSliderDataID = this.$store.getters.getSliderData
            this.getSliderDetay();
        },
        methods: {
            refreshPage(){
                this.loading = false;
                this.detailLoading = false;
                this.getSliderDetay();
            },
            getSliderDetay() {
                this.detailLoading = true;
                const self = this;
                self.imageListMain = [];
                this.sliderForm.resim = []
                sliderService.sliderDetay(this.updateSliderDataID).then(response => {
                    if(response.status == 200){
                        if(response.data !== false){
                            if (response.data.resim) {
                                if(response.data.resim.resim !== ''){
                                    $('#sliderguncelle-resim .el-upload--picture-card').hide();
                                    self.imageListMain.push({
                                        name: response.data.resim,
                                        url: sliderService.imagePath + response.data.resim
                                    });
                                }
                                this.sliderForm.resim.push(response.data.resim)
                            }
                            if (response.data.slider_dil_resim) {
                                if(response.data.slider_dil_resim !== ''){
                                    $('#sliderguncelle-resim .el-upload--picture-card').hide();
                                    self.imageListMainEN.push({
                                        name: response.data.slider_dil_resim,
                                        url: sliderService.imagePath + response.data.slider_dil_resim
                                    });
                                }
                                //this.sliderForm.resimEN.push(this.updateSliderData.resim)
                            }
                            this.sliderForm.updateSliderId = response.data.sliderID;
                            this.sliderForm.anaBaslik = response.data.baslik;
                            this.sliderForm.altBaslik = response.data.altBaslik;
                            this.sliderForm.aciklama = response.data.aciklama;
                            this.sliderForm.sliderKonum = response.data.sliderKonum
                            if(response.data.link1 || response.data.link2 || response.data.linkButon1 || response.data.linkButon2){
                                this.checked = true;
                            }
                            if (response.data.link1) {
                                this.sliderForm.link1 = response.data.link1;
                            }
                            if (response.data.link2) {
                                this.sliderForm.link2 = response.data.link2;
                            }
                            if (response.data.linkButon1) {
                                this.sliderForm.linkButon1 = response.data.linkButon1;
                            }
                            if (response.data.linkButon2) {
                                this.sliderForm.linkButon2 = response.data.linkButon2;
                            }
                            this.sliderForm.durum = response.data.durum;
                        }
                    }
                    this.sliderForm = functions.removeSlashesAndDecodeStrings(this.sliderForm)
                    this.detailLoading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.detailLoading = false;
                }); 
            },
            imageRemove(file) {
                $('#sliderguncelle-resim').find(".el-upload--picture-card").show();
                this.imageListMain.splice(this.imageListMain.indexOf(file), 1);
                this.sliderForm.resim = [];
            },
            imageUpload(file, fileList) {
                $('#sliderguncelle-resim').find(".el-upload--picture-card").hide();
                this.imageListMain = []
                this.sliderForm.resim = []
                if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
                    var item = {
                        name: file.name,
                        url: file.url,
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.imageListMain.push(item)

                    file.raw["tmp_name"] = file.url; // backend file_url'i tmp_name değişkeninden alıyor
                    
                    this.sliderForm.resim.push(file.raw)

                    fileList.pop();
                }
            },
            sliderGuncelle(formName) {
                this.loading = true
                this.$refs[formName].validate((valid, errors) => {
                    if (valid) {
                        sliderService.sliderGuncelle(this.sliderForm.updateSliderId, this.sliderForm.resim[0] ? this.sliderForm.resim[0] : "", this.sliderForm.anaBaslik, this.sliderForm.altBaslik, this.sliderForm.aciklama, this.sliderForm.linkButon1, this.sliderForm.link1,this.sliderForm.linkButon2, this.sliderForm.link2, this.sliderForm.durum, this.sliderForm.sliderKonum).then(response =>{
                            if(response.status == 200){
                                localStorage.setItem("userDataBGSurec", response.token)
                                this.resetForm(formName);
                                EventBus.$emit("sliderList", true)
                                functions.sayfaKapat("sliderguncelle", this);
                            }
                            notification.Status('success', this, response.msg);
                            this.loading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.loading = false;
                        });
                    }else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })  
                            this.loading = false
                        }
                    }
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.imageListMain = [];

                functions.sayfaKapat("updateslider", this);
            }
        }
    }

</script>

<style lang="scss" scoped>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .yanForm .card {
        margin-top: 10px;
    }
</style>